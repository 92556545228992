<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editHeader')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createHeader')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.title"
                                        name="title"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.is_default"
                                         name="is_default"
                                         :label-prefix="labelPrefix"
                                         :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                                         }"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.locale"
                                         :options="getLocales()"
                                         name="locale"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                                         value-field="code"
                                         text-field="name"
                        ></zw-select-group>
                    </b-col>

                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'doc_types') }}</label>
                        <multiselect
                            v-model="form.doc_types"
                            :options="getDocumentTypes()"
                            :multiple="true"
                            :label-prefix="labelPrefix">
                        </multiselect>
                    </b-col>
                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'header') }}</label>
                        <zw-ckeditor v-model="form.header"></zw-ckeditor>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Customer-Category-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                is_default: 0,
                locale: 'de',
                name: '',
                value: '',
            },
            form: {},
            labelPrefix: 'settings.header.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getLocales', 'getDocuments', 'getDocumentTypes']),
        ...mapGetters('Headers', ['getHeader']),
        shown() {
            const store = this.$store.dispatch('Headers/fetchHeader', this.payload.id)

            Promise.all([store])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getHeader()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('Headers/saveHeader', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>